import dynamic from 'next/dynamic';

const NaverMapSystemUI_V2 = dynamic(
  () => import('../../mapTestSrc/mode/maps/baseComp/NaverMapSystemUI_V2'),
  {
    ssr: false,
  }
);
const Page = () => {
  return (
    <>
      <NaverMapSystemUI_V2 />
    </>
  );
};
export default Page;
